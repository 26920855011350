// Додано імпорт React для використання JSX
import React, { useEffect, useState } from "react";
import { FaPlus, FaMinus } from "react-icons/fa";
// import css from "./PDVInventory.module.css";
import { Circles } from "react-loader-spinner";
import apiaxios from "../../common/apiaxios";

const Inventory = () => {
  const [data, setData] = useState([]);
  const [problematicData, setProblematicData] = useState([]);
  const [circle, setCircle] = useState(true);
  const [expandedGroups, setExpandedGroups] = useState({});
  const [selectedWarehouse, setSelectedWarehouse] = useState("ALL");
  const [editingProductId, setEditingProductId] = useState(null);

  // Оновлено функцію toggleGroup для коректного розгортання груп
  const toggleGroup = (id) => {
    setExpandedGroups((prev) => {
      const newState = { ...prev };
      newState[id] = !prev[id];
      return newState;
    });
  };

  useEffect(() => {
    apiaxios
      .get("/catalog/get_products_by_warehouse_sorted_by_category/")
      .then((res) => {
        setData(res.data["data"]);
        setProblematicData(res.data["problem"]);
        setCircle(false);
        console.log(res.data["problem"]);
        console.log(res.data["data"]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleWarehouseChange = (event) => {
    setSelectedWarehouse(event.target.value);
  };

  const handleMinQuantityChange = (productId, newMinQuantity) => {
    setData((prevData) =>
      prevData.map((item) => ({
        ...item,
        children: item.children.map((child) => ({
          ...child,
          products: child.products.map((product) =>
            product.id === productId
              ? { ...product, quantity_min: newMinQuantity }
              : product
          ),
          children: child.children?.map((subChild) => ({
            ...subChild,
            products: subChild.products.map((subProduct) =>
              subProduct.id === productId
                ? { ...subProduct, quantity_min: newMinQuantity }
                : subProduct
            ),
          })),
        })),
      }))
    );
  };

  const saveMinQuantity = (productId, newMinQuantity) => {
    apiaxios
      .post(
        "/sources/save_star_item/",
        [
          {
            id: productId,
            code: selectedWarehouse,
            quantity: newMinQuantity,
          },
        ],
        { headers: { "Content-Type": "application/json" } },
        { withCredentials: true }
      )
      .then((response) => {
        console.log("Мінімальну кількість оновлено", response);
      })
      .catch((error) => {
        console.error("Помилка при оновленні мінімальної кількості", error);
      });
  };

  const handleCellClick = (productId) => {
    if (selectedWarehouse === "ALL") {
      alert(
        "Змінювати мінімальну кількість можна тільки тоді, коли обрано склад!"
      );
    } else {
      setEditingProductId(productId);
    }
  };

  const handleInputBlur = (productId, newMinQuantity) => {
    saveMinQuantity(productId, newMinQuantity);
    setEditingProductId(null);
  };

  // Логіка фільтрації залишена тільки для першої таблиці
  const filteredData = data
    .map((item) => ({
      ...item,
      children: item.children
        .map((child) => ({
          ...child,
          products: child.products.filter(
            (product) =>
              selectedWarehouse === "ALL" ||
              product.warehouse === selectedWarehouse
          ),
          children: child.children
            ?.map((subChild) => ({
              ...subChild,
              products: subChild.products.filter(
                (subProduct) =>
                  selectedWarehouse === "ALL" ||
                  subProduct.warehouse === selectedWarehouse
              ),
            }))
            .filter((subChild) => subChild.products.length > 0),
        }))
        .filter(
          (child) => child.products.length > 0 || child.children?.length > 0
        ),
    }))
    .filter((item) => item.children.length > 0);

  // Видалено фільтри для другої таблиці
  const filteredProblematicData = problematicData;

  const totalProducts = filteredData.reduce((sum, item) => {
    return (
      sum +
      item.children.reduce((childSum, child) => {
        return (
          childSum +
          child.products.length +
          (child.children?.reduce((subChildSum, subChild) => {
            return subChildSum + subChild.products.length;
          }, 0) || 0)
        );
      }, 0)
    );
  }, 0);

  return (
    <div>
      <h1 style={{ textAlign: "center", marginBottom: "20px" }}>
        Залишки по складах та мінімум на складі
      </h1>
      <div style={{ marginBottom: "20px" }}>
        <label htmlFor="warehouse-select" style={{ marginRight: "10px" }}>
          Виберіть склад:
        </label>
        <select
          id="warehouse-select"
          value={selectedWarehouse}
          onChange={handleWarehouseChange}
        >
          <option value="ALL">Всі</option>
          <option value="ITS">ITS</option>
          <option value="SC">SC</option>
        </select>
        <span style={{ marginLeft: "20px", fontWeight: "bold" }}>
          Кількість товарів: {totalProducts}
        </span>
      </div>
      {circle && (
        // Виправлено стилі для центрування спінера по вертикалі та горизонталі
        <div
          className="spinner"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute", // Заміна fixed на absolute
            top: 0,
            left: 0,
            right: 0,
            bottom: 0, // Додає рівномірне позиціонування
            margin: "auto", // Центрування по всіх осях
          }}
        >
          <Circles
            height="150"
            width="150"
            color="rgb(50, 130, 250)"
            ariaLabel="circles-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      )}
      {!circle && filteredData && (
        <div style={{ marginTop: "10px" }}>
          <table
            style={{
              width: "100%",
              borderCollapse: "collapse",
              fontSize: "13px",
              userSelect: "text", // Дозволяє виділення тексту
            }}
          >
            <thead>
              <tr style={{ backgroundColor: "#b0b0b0" }}>
                <th style={{ border: "1px solid black", padding: "5px" }}>
                  Код
                </th>
                <th style={{ border: "1px solid black", padding: "5px" }}>
                  Назва
                </th>
                <th style={{ border: "1px solid black", padding: "5px" }}>
                  Кількість
                </th>
                <th style={{ border: "1px solid black", padding: "5px" }}>
                  Мін. кількість
                </th>
                <th style={{ border: "1px solid black", padding: "5px" }}>
                  Замовити
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredData.map((root) => (
                <React.Fragment key={root.id}>
                  <tr
                    key={root.id}
                    style={{ backgroundColor: "#d0d0d0", cursor: "pointer" }}
                    onClick={() => toggleGroup(root.id)}
                  >
                    <td
                      colSpan="5"
                      style={{
                        border: "1px solid black",
                        padding: "5px",
                        fontWeight: "bold",
                        paddingLeft: "10px",
                      }}
                    >
                      {expandedGroups[root.id] ? (
                        <FaMinus style={{ marginRight: "10px" }} />
                      ) : (
                        <FaPlus style={{ marginRight: "10px" }} />
                      )}
                      {root.name}
                    </td>
                  </tr>
                  {expandedGroups[root.id] &&
                    root.children &&
                    root.children.map((child) => (
                      <React.Fragment key={child.id}>
                        <tr
                          key={child.id}
                          style={{
                            backgroundColor: "#e0e0e0",
                            cursor: "pointer",
                          }}
                          onClick={() => toggleGroup(child.id)}
                        >
                          <td
                            colSpan="5"
                            style={{
                              border: "1px solid black",
                              padding: "5px",
                              fontWeight: "bold",
                              paddingLeft: "30px",
                            }}
                          >
                            {expandedGroups[child.id] ? (
                              <FaMinus style={{ marginRight: "10px" }} />
                            ) : (
                              <FaPlus style={{ marginRight: "10px" }} />
                            )}
                            {child.name}
                          </td>
                        </tr>
                        {expandedGroups[child.id] &&
                          child.products &&
                          child.products.map((product) => (
                            <tr key={product.id}>
                              <td
                                style={{
                                  border: "1px solid black",
                                  padding: "5px",
                                  userSelect: "text", // Дозволяє виділення тексту
                                  overflow: "visible", // Запобігає перекриттю тексту
                                  whiteSpace: "normal", // Дозволяє перенос тексту
                                  pointerEvents: "auto", // Забезпечує доступність тексту для виділення
                                }}
                              >
                                {product.id}
                              </td>
                              <td
                                style={{
                                  border: "1px solid black",
                                  padding: "5px",
                                  userSelect: "text", // Дозволяє виділення тексту
                                  overflow: "visible", // Запобігає перекриттю тексту
                                  whiteSpace: "normal", // Дозволяє перенос тексту
                                  pointerEvents: "auto", // Забезпечує доступність тексту для виділення
                                }}
                              >
                                {product.name}
                              </td>
                              <td
                                style={{
                                  border: "1px solid black",
                                  padding: "5px",
                                }}
                              >
                                {product.quantity}
                              </td>
                              <td
                                style={{
                                  border: "1px solid black",
                                  padding: "5px",
                                  cursor:
                                    selectedWarehouse !== "ALL"
                                      ? "pointer"
                                      : "default",
                                }}
                                onClick={() => handleCellClick(product.id)}
                              >
                                {editingProductId === product.id ? (
                                  <input
                                    type="number"
                                    value={product.quantity_min}
                                    onChange={(e) =>
                                      handleMinQuantityChange(
                                        product.id,
                                        Number(e.target.value)
                                      )
                                    }
                                    onBlur={(e) =>
                                      handleInputBlur(
                                        product.id,
                                        Number(e.target.value)
                                      )
                                    }
                                    style={{ width: "60px" }}
                                    autoFocus
                                  />
                                ) : (
                                  product.quantity_min
                                )}
                              </td>
                              {/* Додано стиль для відображення кількості до замовлення червоним кольором */}
                              <td
                                style={{
                                  border: "1px solid black",
                                  padding: "5px",
                                  color:
                                    product.quantity_min > product.quantity
                                      ? "red"
                                      : "black", // Червоний колір, якщо кількість до замовлення більше 0
                                }}
                              >
                                {product.quantity_min > product.quantity
                                  ? product.quantity_min - product.quantity
                                  : ""}
                              </td>
                            </tr>
                          ))}
                        {/* Додано перевірку та логіку для розгортання груп третього рівня */}
                        {expandedGroups[child.id] &&
                          child.children &&
                          child.children.map((subChild) => (
                            <React.Fragment key={subChild.id}>
                              <tr
                                key={subChild.id}
                                style={{
                                  backgroundColor: "#f0f0f0",
                                  cursor: "pointer",
                                }}
                                onClick={() => toggleGroup(subChild.id)}
                              >
                                <td
                                  colSpan="5"
                                  style={{
                                    border: "1px solid black",
                                    padding: "5px",
                                    fontWeight: "bold",
                                    paddingLeft: "50px",
                                  }}
                                >
                                  {expandedGroups[subChild.id] ? (
                                    <FaMinus style={{ marginRight: "10px" }} />
                                  ) : (
                                    <FaPlus style={{ marginRight: "10px" }} />
                                  )}
                                  {subChild.name}
                                </td>
                              </tr>
                              {expandedGroups[subChild.id] &&
                                subChild.products &&
                                subChild.products.map((subProduct) => (
                                  <tr key={subProduct.id}>
                                    <td
                                      style={{
                                        border: "1px solid black",
                                        padding: "5px",
                                        userSelect: "text",
                                        overflow: "visible",
                                        whiteSpace: "normal",
                                        pointerEvents: "auto",
                                      }}
                                    >
                                      {subProduct.id}
                                    </td>
                                    <td
                                      style={{
                                        border: "1px solid black",
                                        padding: "5px",
                                        userSelect: "text",
                                        overflow: "visible",
                                        whiteSpace: "normal",
                                        pointerEvents: "auto",
                                      }}
                                    >
                                      {subProduct.name}
                                    </td>
                                    <td
                                      style={{
                                        border: "1px solid black",
                                        padding: "5px",
                                      }}
                                    >
                                      {subProduct.quantity}
                                    </td>
                                    <td
                                      style={{
                                        border: "1px solid black",
                                        padding: "5px",
                                        cursor:
                                          selectedWarehouse !== "ALL"
                                            ? "pointer"
                                            : "default",
                                      }}
                                      onClick={() =>
                                        handleCellClick(subProduct.id)
                                      }
                                    >
                                      {editingProductId === subProduct.id ? (
                                        <input
                                          type="number"
                                          value={subProduct.quantity_min}
                                          onChange={(e) =>
                                            handleMinQuantityChange(
                                              subProduct.id,
                                              Number(e.target.value)
                                            )
                                          }
                                          onBlur={(e) =>
                                            handleInputBlur(
                                              subProduct.id,
                                              Number(e.target.value)
                                            )
                                          }
                                          style={{ width: "60px" }}
                                          autoFocus
                                        />
                                      ) : (
                                        subProduct.quantity_min
                                      )}
                                    </td>
                                    <td
                                      style={{
                                        border: "1px solid black",
                                        padding: "5px",
                                        color:
                                          subProduct.quantity_min >
                                          subProduct.quantity
                                            ? "red"
                                            : "black", // Червоний колір, якщо кількість до замовлення більше 0
                                      }}
                                    >
                                      {subProduct.quantity_min >
                                      subProduct.quantity
                                        ? subProduct.quantity_min -
                                          subProduct.quantity
                                        : ""}
                                    </td>
                                  </tr>
                                ))}
                            </React.Fragment>
                          ))}
                      </React.Fragment>
                    ))}
                </React.Fragment>
              ))}
            </tbody>
          </table>
          <h2 style={{ marginTop: "20px" }}>Проблемні товари</h2>
          <table
            style={{
              width: "100%",
              borderCollapse: "collapse",
              fontSize: "13px",
              userSelect: "text",
            }}
          >
            <thead>
              <tr style={{ backgroundColor: "#b0b0b0" }}>
                <th style={{ border: "1px solid black", padding: "5px" }}>
                  ID
                </th>
                <th style={{ border: "1px solid black", padding: "5px" }}>
                  Код
                </th>
                <th style={{ border: "1px solid black", padding: "5px" }}>
                  Код ITS
                </th>
                <th style={{ border: "1px solid black", padding: "5px" }}>
                  Ціна
                </th>
                <th style={{ border: "1px solid black", padding: "5px" }}>
                  Кількість
                </th>
                <th style={{ border: "1px solid black", padding: "5px" }}>
                  Склад
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredProblematicData.map((item) => (
                <tr key={item.id}>
                  <td
                    style={{
                      border: "1px solid black",
                      padding: "5px",
                      userSelect: "text", // Дозволяє виділення тексту
                    }}
                  >
                    {item.id}
                  </td>
                  <td
                    style={{
                      border: "1px solid black",
                      padding: "5px",
                      userSelect: "text", // Дозволяє виділення тексту
                    }}
                  >
                    {item.code}
                  </td>
                  <td
                    style={{
                      border: "1px solid black",
                      padding: "5px",
                      userSelect: "text", // Дозволяє виділення тексту
                    }}
                  >
                    {item.code_its}
                  </td>
                  <td
                    style={{
                      border: "1px solid black",
                      padding: "5px",
                      userSelect: "text", // Дозволяє виділення тексту
                    }}
                  >
                    {item.price}
                  </td>
                  <td
                    style={{
                      border: "1px solid black",
                      padding: "5px",
                      userSelect: "text", // Дозволяє виділення тексту
                    }}
                  >
                    {item.quantity}
                  </td>
                  <td
                    style={{
                      border: "1px solid black",
                      padding: "5px",
                      userSelect: "text", // Дозволяє виділення тексту
                    }}
                  >
                    {item.warehouse}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default Inventory;
