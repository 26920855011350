import React, { useEffect, useState } from "react";
import { Circles } from "react-loader-spinner";
import apiaxios from "../../common/apiaxios";

const InventoryPDV = () => {
  const [data, setData] = useState([]);
  const [circle, setCircle] = useState(true);

  useEffect(() => {
    apiaxios
      .get("/catalog/get_pdv_inventory_new/")
      .then((res) => {
        setData(res.data["data"]);
        setCircle(false);
        console.log(res.data["data"]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const renderTable = (data) => {
    return (
      <table style={{ width: "100%", borderCollapse: "collapse" }}>
        <thead>
          <tr style={{ backgroundColor: "#b0b0b0" }}>
            <th style={{ border: "1px solid black", padding: "5px" }}>ID</th>
            <th style={{ border: "1px solid black", padding: "5px" }}>
              Брейн код
            </th>
            <th style={{ border: "1px solid black", padding: "5px" }}>Назва</th>
            <th style={{ border: "1px solid black", padding: "5px" }}>
              Кількість
            </th>
            <th style={{ border: "1px solid black", padding: "5px" }}>Ціна</th>
            <th style={{ border: "1px solid black", padding: "5px" }}>
              Кількість ПДВ
            </th>
            <th style={{ border: "1px solid black", padding: "5px" }}>
              Ціна ПДВ
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((category) => (
            <React.Fragment key={category.id}>
              <tr style={{ backgroundColor: "#d0d0d0" }}>
                <td
                  colSpan="7"
                  style={{
                    border: "1px solid black",
                    padding: "5px",
                    fontWeight: "bold",
                  }}
                >
                  {category.name}
                </td>
              </tr>
              {category.children.map((subCategory) => (
                <React.Fragment key={subCategory.id}>
                  <tr style={{ backgroundColor: "#e0e0e0" }}>
                    <td
                      colSpan="7"
                      style={{
                        border: "1px solid black",
                        padding: "5px",
                        paddingLeft: "20px",
                      }}
                    >
                      {subCategory.name}
                    </td>
                  </tr>
                  {subCategory.products.map((product) => (
                    <tr key={product.id}>
                      <td style={{ border: "1px solid black", padding: "5px" }}>
                        {product.id}
                      </td>
                      <td style={{ border: "1px solid black", padding: "5px" }}>
                        {product.product_code}
                      </td>
                      <td style={{ border: "1px solid black", padding: "5px" }}>
                        {product.name}
                      </td>
                      <td style={{ border: "1px solid black", padding: "5px" }}>
                        {product.quantity}
                      </td>
                      <td style={{ border: "1px solid black", padding: "5px" }}>
                        {product.price}
                      </td>
                      <td style={{ border: "1px solid black", padding: "5px" }}>
                        {product.quantity_pdv}
                      </td>
                      <td style={{ border: "1px solid black", padding: "5px" }}>
                        {product.price_pdv}
                      </td>
                    </tr>
                  ))}
                </React.Fragment>
              ))}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    );
  };

  return (
    <div>
      {circle && (
        <div
          className="spinner"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            margin: "auto",
          }}
        >
          <Circles
            height="150"
            width="150"
            color="rgb(50, 130, 250)"
            ariaLabel="circles-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      )}
      {!circle && (
        <div>
          <h1
            style={{
              textAlign: "center",
              marginBottom: "15px",
              fontSize: "1.5rem",
            }}
          >
            Залишки по складах та обліку з ПДВ
          </h1>
          {renderTable(data)}
        </div>
      )}
    </div>
  );
};

export default InventoryPDV;
