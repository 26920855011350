import dataProvider from "./dataProvider";
import authProvider from "./authProvider";
import { Dashboard } from "./Dashboard";
import { PortalLayout } from "./PortalLayout";
import { i18nProvider } from "./UkrainianLang";
import { Admin, CustomRoutes, Resource } from "react-admin";
import {
  PartnersCreate,
  PartnersEdit,
  PartnersList,
  PartnersShow,
} from "./components/Dictionaries/Partners";
import AccountBoxTwoToneIcon from "@mui/icons-material/AccountBoxTwoTone";
import AccountBalanceWalletTwoToneIcon from "@mui/icons-material/AccountBalanceWalletTwoTone";
import ManTwoToneIcon from "@mui/icons-material/ManTwoTone";
import Groups2TwoToneIcon from "@mui/icons-material/Groups2TwoTone";
import PeopleTwoToneIcon from "@mui/icons-material/PeopleTwoTone";
import FactoryTwoToneIcon from "@mui/icons-material/FactoryTwoTone";
// https://mui.com/material-ui/material-icons/
import {
  ProductsCreate,
  ProductsEdit,
  ProductsList,
  ProductsShow,
} from "./components/Dictionaries/Products";
import { OrdersList, OrdersShow } from "./components/Registers/Orders";
import { Route } from "react-router-dom";
import ProductsListPage from "../pages/ProductsListPage";
import { CatalogStartPage } from "./components/CatalogStartPage";
import GroupMenu from "../components/GroupMenu/GroupMenu";
import ProductsFavoriteLists from "../components/ProductFavoriteCompare/ProductFavoriteLists";
import ProductCompareLists from "../components/ProductFavoriteCompare/ProductCompareLists";
import {
  UsersCreate,
  UsersEdit,
  UsersList,
  UsersShow,
} from "./components/Dictionaries/Users";
import {
  StaffCreate,
  StaffEdit,
  StaffList,
  StaffShow,
} from "./components/Dictionaries/Staff";
import {
  FirmsList,
  FirmsCreate,
  FirmsEdit,
  FirmsShow,
} from "./components/Dictionaries/Firms";
import {
  AccountsCreate,
  AccountsEdit,
  AccountsList,
  AccountsShow,
} from "./components/Dictionaries/Accounts";
import {
  SourcesCreate,
  SourcesEdit,
  SourcesList,
  SourcesShow,
} from "./components/Dictionaries/Sources";
import { DeliveryList, DeliveryShow } from "./components/Dictionaries/Delivery";
import {
  BrandsCreate,
  BrandsEdit,
  BrandsList,
  BrandsShow,
} from "./components/Dictionaries/Brands";
import {
  BrandsVendorsCreate,
  BrandsVendorsEdit,
  BrandsVendorsList,
  BrandsVendorsShow,
} from "./components/Dictionaries/BrandsVendors";
import {
  PricesInCreate,
  PricesInEdit,
  PricesInList,
  PricesInShow,
} from "./components/Dictionaries/PricesIn";
import {
  PricesOutCreate,
  PricesOutEdit,
  PricesOutList,
  PricesOutShow,
} from "./components/Dictionaries/PricesOut";
import {
  CategoriesCreate,
  CategoriesEdit,
  CategoriesList,
  CategoriesShow,
} from "./components/Dictionaries/Categories";
import ProductDetails from "../components/ProductDetails/ProductDetails";
import { OrderedRowsList } from "./components/Registers/OrderedRows";
import PriceList from "../pages/PriceList";
import Inventory from "../components/Inventory/Inventory";
import InventoryPDV from "../components/Inventory/InventoryPDV";

const AdminPortal = ({ basename }) => {
  return (
    <Admin
      basename={basename}
      authProvider={authProvider}
      dataProvider={dataProvider}
      i18nProvider={i18nProvider}
      dashboard={Dashboard}
      layout={PortalLayout}
    >
      <Resource
        name="firms/get_firms"
        list={FirmsList}
        show={FirmsShow}
        edit={FirmsEdit}
        create={FirmsCreate}
        options={{ label: "Фірми" }}
        icon={AccountBoxTwoToneIcon}
      />
      <Resource
        name="firms/get_accounts"
        list={AccountsList}
        show={AccountsShow}
        edit={AccountsEdit}
        create={AccountsCreate}
        options={{ label: "Рахунки" }}
        icon={AccountBalanceWalletTwoToneIcon}
      />
      <Resource
        name="staff/get_staff"
        list={StaffList}
        show={StaffShow}
        edit={StaffEdit}
        create={StaffCreate}
        options={{ label: "Співробітники" }}
        icon={ManTwoToneIcon}
      />
      <Resource
        name="partners/get_partners"
        list={PartnersList}
        show={PartnersShow}
        edit={PartnersEdit}
        create={PartnersCreate}
        options={{ label: "Контрагенти" }}
        icon={Groups2TwoToneIcon}
      />
      <Resource
        name="users/get_users"
        list={UsersList}
        show={UsersShow}
        edit={UsersEdit}
        create={UsersCreate}
        options={{ label: "Користувачі" }}
        icon={PeopleTwoToneIcon}
      />
      <Resource
        name="sources/get_sources"
        list={SourcesList}
        show={SourcesShow}
        edit={SourcesEdit}
        create={SourcesCreate}
        options={{ label: "Постачальники, склади" }}
        icon={FactoryTwoToneIcon}
      />
      <Resource
        name="catalog/get_brands"
        list={BrandsList}
        show={BrandsShow}
        edit={BrandsEdit}
        create={BrandsCreate}
        options={{ label: "Виробники з каталога" }}
        icon={FactoryTwoToneIcon}
      />
      <Resource
        name="catalog/get_brands_vendors"
        list={BrandsVendorsList}
        show={BrandsVendorsShow}
        edit={BrandsVendorsEdit}
        create={BrandsVendorsCreate}
        options={{ label: "Виробники постачальників" }}
        icon={FactoryTwoToneIcon}
      />
      <Resource
        name="catalog/get_products"
        list={ProductsList}
        show={ProductsShow}
        edit={ProductsEdit}
        create={ProductsCreate}
        options={{ label: "Товари" }}
        icon={FactoryTwoToneIcon}
      />
      <Resource
        name="catalog/get_categories"
        list={CategoriesList}
        show={CategoriesShow}
        edit={CategoriesEdit}
        create={CategoriesCreate}
        options={{ label: "Категорії товару" }}
        icon={FactoryTwoToneIcon}
      />
      <Resource
        name="catalog/get_prices_in"
        list={PricesInList}
        show={PricesInShow}
        edit={PricesInEdit}
        create={PricesInCreate}
        options={{ label: "Ціни вхідні" }}
      />
      <Resource
        name="catalog/get_prices_out"
        list={PricesOutList}
        show={PricesOutShow}
        edit={PricesOutEdit}
        create={PricesOutCreate}
        options={{ label: "Ціни вихідні" }}
      />
      {/* <Resource
        name="catalog/products_list"
        list={ProductsList}
        show={ProductsShow}
        options={{ label: "Каталог" }}
      /> */}
      <Resource
        name="orders/delivery"
        list={DeliveryList}
        show={DeliveryShow}
        options={{ label: "Адреси доставки" }}
      />
      <Resource
        name="orders/list"
        list={OrdersList}
        show={OrdersShow}
        options={{ label: "Замовлення покупців" }}
      />
      <Resource
        name="orders/ordering_rows"
        list={OrderedRowsList}
        options={{ label: "Замовлені товари" }}
      />
      <CustomRoutes>
        <Route path="catalog/main" element={<CatalogStartPage />} />
      </CustomRoutes>
      <CustomRoutes>
        <Route path="catalog/price_inventory" element={<PriceList />} />
      </CustomRoutes>
      <CustomRoutes>
        <Route path="catalog/inventory" element={<Inventory />} />
      </CustomRoutes>
      <CustomRoutes>
        <Route path="catalog/inventory_pdv" element={<InventoryPDV />} />
      </CustomRoutes>
      <CustomRoutes>
        <Route path="catalog/favorite" element={<ProductsFavoriteLists />} />
      </CustomRoutes>
      <CustomRoutes>
        <Route path="catalog/compare" element={<ProductCompareLists />} />
      </CustomRoutes>
      <CustomRoutes>
        <Route
          path="catalog/category/:categoryID"
          element={<ProductsListPage />}
        />
      </CustomRoutes>
      <CustomRoutes>
        <Route path="catalog/group_menu/:categoryID" element={<GroupMenu />} />
      </CustomRoutes>
      <CustomRoutes>
        <Route
          path="catalog/product_details/:id"
          element={<ProductDetails />}
        />
      </CustomRoutes>
      <CustomRoutes>
        <Route path="search" element={<ProductsListPage />} />
      </CustomRoutes>
      {/* <CustomRoutes>
        <Route path="catalog/shopping_cart" element={<BasketEdit />} />
      </CustomRoutes> */}
    </Admin>
  );
};

export default AdminPortal;
